import { Text, Spinner, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const HueCallback = () => {
    const [message, setMessage] = useState("Authenticating... Please wait.");
    const [authenticated, setAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const authenticateUser = async () => {
            const params = new URLSearchParams(window.location.search);
            const pkce = params.get('pkce');
            const code = params.get('code');
            const state = params.get('state');

            // Send code and state to the backend for processing
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/hue/callback", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ pkce, code, state }),
                credentials: 'include'
            });

            if (response.ok) {
                setMessage("Authenticated successfully! Redirecting to home...");
                setAuthenticated(true);
                // Show a message for a few seconds
                setTimeout(() => {
                    navigate('/home');
                }, 2000); // 2 seconds
            } else {
                console.log(response);
                setMessage("Authentication failed!");
            }
        };

        authenticateUser();
    }, [navigate]);

    return (
        <Stack>
            <Text>{message}</Text>
            {!authenticated ?? <Spinner color='blue.500'/>}
        </Stack>
    );
}

export default HueCallback;
