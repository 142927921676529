import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from './AuthContext';

const LoginRequired = () => {
	const { isLoggedIn } = useAuth();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (isLoggedIn !== null) {
			setLoading(false);
		}
	}, [isLoggedIn]);

	if (loading) {
		return <div>Loading...</div>;
	}
	return (
		isLoggedIn ? <Outlet /> : <Navigate to='/login' />
	);
};

const HueAuthRequired = () => {
	const { isAuthorized } = useAuth();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (isAuthorized !== null) {
			setLoading(false);
		}
	}, [isAuthorized]);

	if (loading) {
		return <div>Loading...</div>;
	}
	return (
		isAuthorized ? <Outlet /> : <Navigate to='/hue' />
	);
};

export { LoginRequired, HueAuthRequired };
