import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Flex,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

const TermsOfService = () => {
  const navigate = useNavigate();
  return (
    <Box
      bgGradient="linear(to-b, blackAlpha.800, blackAlpha.600)"
      color="white"
      minH="100vh"
      p={4}
      overflowY="auto" // Enable vertical scrolling
      css={{
        '&::-webkit-scrollbar': {
          width: '0px',
          background: 'transparent',
        },
      }}
    >
      <Container maxW="md" centerContent>
        <Flex
          w="full"
          align="center"
          mb={4}
          bgGradient="linear(to-b, blackAlpha.900, transparent)"
          p={2}
          borderRadius="md"
        >
          <IconButton
            icon={<ArrowBackIcon />}
            variant="outline"
            aria-label="Back"
            colorScheme="whiteAlpha"
            size="sm" // Smaller size for the button
            width="2rem" // Custom width for the button
            height="2rem" // Custom height for the button
            onClick={() => navigate('/')}
          />
          <Text ml={2} fontSize="xl" fontWeight="bold">
            Terms of Service
          </Text>
        </Flex>
        <VStack spacing={4} textAlign="left" w="full" maxH="70vh" overflowY="auto" css={{
          '&::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent',
          },
        }}>
          <Text fontSize="md">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non enim eget justo pharetra egestas. Fusce vel ultricies enim. Maecenas nec justo vitae velit interdum elementum in vitae est. Maecenas suscipit lacinia interdum. Vestibulum et pulvinar dolor. Cras rutrum ipsum eget sem venenatis, in sollicitudin mauris ultricies. Mauris diam magna, egestas vel tellus at, rhoncus faucibus augue. Quisque pulvinar nibh neque, ac fringilla massa auctor nec. Aliquam eget neque dignissim, eleifend lorem eu, dictum dolor. Vivamus imperdiet dui quis dolor ultricies, nec laoreet ante fringilla. Nam venenatis massa quis metus scelerisque vestibulum.
          </Text>
          <Text fontSize="md">
            Quisque pulvinar nibh neque, ac fringilla massa auctor nec. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non enim eget justo pharetra egestas. Fusce vel ultricies enim. Maecenas nec justo vitae velit interdum elementum in vitae est. Maecenas suscipit lacinia interdum. Vestibulum et pulvinar dolor. Cras rutrum ipsum eget sem venenatis, in sollicitudin mauris ultricies. Mauris diam magna, egestas vel tellus at, rhoncus faucibus augue. Quisque pulvinar nibh neque, ac fringilla massa auctor nec. Aliquam eget neque dignissim, eleifend lorem eu, dictum dolor. Vivamus imperdiet dui quis dolor ultricies, nec laoreet ante fringilla. Nam venenatis massa quis metus scelerisque vestibulum.
          </Text>
          <Text fontSize="md">
            Curabitur nisl ligula, dapibus eu vehicula id, hendrerit ut mi. Sed at velit sed ligula placerat elementum. Sed bibendum nunc at felis aliquam scelerisque. Cras id nisi ac ipsum sagittis vehicula id sed magna. Morbi et ex semper, auctor nulla at, maximus est. Mauris tincidunt turpis magna, in suscipit ipsum sagittis nec. Aliquam eu venenatis est. Aliquam molestie sollicitudin leo at porttitor. Nam consectetur pulvinar nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non enim eget justo pharetra egestas. Fusce vel ultricies enim. Maecenas nec justo vitae velit interdum elementum in vitae est. Maecenas suscipit lacinia interdum. Vestibulum et pulvinar dolor. Cras rutrum ipsum eget sem venenatis, in sollicitudin mauris ultricies. Mauris diam magna, egestas vel tellus at, rhoncus faucibus augue. Quisque pulvinar nibh neque, ac fringilla massa auctor nec. Aliquam eget neque dignissim, eleifend lorem eu, dictum dolor. Vivamus imperdiet dui quis dolor ultricies, nec laoreet ante fringilla. Nam venenatis massa quis metus scelerisque vestibulum.
          </Text>
        </VStack>
        <Box
          w="full"
          bgGradient="linear(to-t, blackAlpha.900, transparent)"
          mt={8}
          p={4}
          borderRadius="md"
        >
          <Button
            variant='confirm'
            onClick={() => navigate("/Auth")}
          >
            I accept
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default TermsOfService;
