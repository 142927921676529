import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Heading, Text, Center, Button, Checkbox, InputRightElement, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, InputGroup, useInputGroupStyles, HStack } from '@chakra-ui/react'

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessages, setErrorMessages] = useState({
        email: '',
        password: '',
        confirmPassword: '',
    });

    const navigate = useNavigate();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setErrorMessages((prevErrors) => ({
            ...prevErrors,
            email: '',
        }));
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setErrorMessages((prevErrors) => ({
            ...prevErrors,
            password: '',
        }));
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        setErrorMessages((prevErrors) => ({
            ...prevErrors,
            confirmPassword: '',
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validation logic
        const errors = {};

        if (!email.trim()) {
            errors.email = 'Email is required';
        }

        if (!password.trim()) {
            errors.password = 'Password is required';
        }

        if (!confirmPassword.trim()) {
            errors.confirmPassword = 'Confirm Password is required';
        } else if (password !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }

        // Set error messages
        setErrorMessages(errors);

        // Submit logic (if validation passes)
        if (Object.keys(errors).length === 0) {
            // Perform submit action, e.g., API call or navigation
            try {
				const resp = await fetch(process.env.REACT_APP_BACKEND_URL + '/auth/register', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ 
						"username": email,
						"password": password }),
                    credentials: 'include'
				});
				switch (resp.status) {
					case 400:
						// username or password not sent or user already exists
						// TODO UI
						break;
					case 201:
						// successful registration
						navigate('/hue');
						break;
				}
			} catch (e) {
				console.log(e);
			}
        }
    };


    return (
        <Stack align="center" mt="40px">
            <Stack
                justify="flex-start"
                align="flex-start"
                spacing="48px"
            >
                <Stack
                    justify="flex-start"
                    align="flex-start"
                    spacing="16px"
                    alignSelf="stretch"
                >
                    <Heading>
                        Welcome!
                    </Heading>
                    <Text>
                        Register with email and password.
                    </Text>
                </Stack>

                <form onSubmit={handleSubmit}>
                    <FormControl isInvalid={!!errorMessages.email}>
                        <Input type="email" placeholder="Email" value={email} onChange={handleEmailChange} />
                        <FormErrorMessage>{errorMessages.email}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errorMessages.password} mt={4}>
                        <Input type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
                        <FormErrorMessage>{errorMessages.password}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errorMessages.confirmPassword} mt={4}>
                        <Input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                        />
                        <FormErrorMessage>{errorMessages.confirmPassword}</FormErrorMessage>
                    </FormControl>

                    <Button variant="confirm" type="submit" mt={4}>
                        Register
                    </Button>
                </form>

                <Stack justify="flex-start" spacing="24px">
                    <Center>
                        <Text>
                            Or continue with
                        </Text>
                    </Center>

                    <Button variant="outline">
                        Google
                    </Button>
                    <Button variant="outline">
                        Apple
                    </Button>
                    <Button variant="outline">
                        Phillips Hue
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Register;