import React, { useState, useEffect } from 'react';

import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import { LoginRequired, HueAuthRequired } from './PrivateRoutes';

import Home from './pages/Home';
import Start from './pages/Start';
import TermsOfService from './pages/TermsOfService';
import Auth from './pages/Auth';
import Login from './pages/Login';
import Register from './pages/Register';
import Profile from './pages/Profile';
import Spaces from './pages/Spaces';
import Chat from './pages/Chat';
import Power from './pages/Power';
import Hue from './pages/Hue';
import HueCallback from './pages/HueCallback';

function App() {
	return (
		<AuthProvider>
			<Router>
				<div className="App">
					<Routes>
						<Route element={<LoginRequired />}>
							<Route path="/hue" element={<Hue />} />
							<Route path="/hue_callback" element={<HueCallback />} />
							<Route element={<HueAuthRequired />}>
								<Route path="/profile" element={<Profile />} />
								<Route path="/home" element={<Home />} />
								<Route path="/spaces" element={<Spaces />} />
								<Route path="/chat" element={<Chat />} />
								<Route path="/power" element={<Power />} />
							</Route>
						</Route>

						<Route path="/" exact element={<Start />} />
						<Route path="/auth" element={<Auth />} />
						<Route path="/login" element={<Login />} />
						<Route path="/register" element={<Register />} />
						<Route path="/TOS" element={<TermsOfService />} />
						<Route path="*" element={<p>There's nothing here: 404!</p>} />
					</Routes>
				</div>
			</Router>
		</AuthProvider>
	);
}

export default App;
