import { Center, Stack, Text, Button } from "@chakra-ui/react";

const Hue = () => {
    const authenticateWithHue = async () => {
        try {
            const resp = await fetch(process.env.REACT_APP_BACKEND_URL + "/hue/auth", {
                method: 'GET',
                credentials: 'include'
            });
            if (resp.ok) {
                const data = await resp.json();
                if (data.redirect_url) {
                    window.location.href = data.redirect_url;
                }
            } else {
                console.error('Failed to fetch redirect URL');
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Stack align="center" p="20px">
            <Text>
                Authenticate with Phillips Hue. You will be redirected to the Phillips Hue website.
            </Text>
            <Button variant="confirm" onClick={authenticateWithHue}>Authenticate</Button>
        </Stack>
    );
}


export default Hue;