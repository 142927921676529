// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background-color: #05050E;
    background-image: 
      radial-gradient(circle at 45deg, rgba(8, 196, 208, 0.3), rgba(34, 35, 72, 0)),
      radial-gradient(circle at 45deg, rgba(141, 37, 237, 0.3), rgba(34, 35, 72, 0));
    background-size: cover;
    background-blend-mode: overlay;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/background.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB;;oFAEgF;IAChF,sBAAsB;IACtB,8BAA8B;EAChC","sourcesContent":["body {\n    background-color: #05050E;\n    background-image: \n      radial-gradient(circle at 45deg, rgba(8, 196, 208, 0.3), rgba(34, 35, 72, 0)),\n      radial-gradient(circle at 45deg, rgba(141, 37, 237, 0.3), rgba(34, 35, 72, 0));\n    background-size: cover;\n    background-blend-mode: overlay;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
