import { useState } from 'react';
import { Stack, Heading, Text, Center, Button, Checkbox, InputRightElement, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, InputGroup, useInputGroupStyles, HStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../AuthContext";

const Login = () => {
	const { login } = useAuth();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errorMessages, setErrorMessages] = useState({
		email: '',
		password: '',
	});

	const navigate = useNavigate();

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
		setErrorMessages((prevErrors) => ({
			...prevErrors,
			email: '',
		}));
	};

	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
		setErrorMessages((prevErrors) => ({
			...prevErrors,
			password: '',
		}));
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		// Validation logic
		const errors = {};

		if (!email.trim()) {
			errors.email = 'Email is required';
		}

		if (!password.trim()) {
			errors.password = 'Password is required';
		}

		// Set error messages
		setErrorMessages(errors);

		// Submit logic (if validation passes)
		if (Object.keys(errors).length === 0) {
			// Perform submit action, e.g., API call or navigation
			const isLoggedIn = await login(email, password);
			if (isLoggedIn)
			{
				navigate('/home');
			}
		}
	};

	const [show, setShow] = useState(false);
	const handleClick = () => setShow(!show);

	return (
		<Stack align="center" mt="40px">
			<Stack
				justify="flex-start"
				align="flex-start"
				spacing="48px"
			>
				<Stack
					justify="flex-start"
					align="flex-start"
					spacing="16px"
					alignSelf="stretch"
				>
					<Heading>
						Welcome!
					</Heading>
					<Text>
						Log in with your account.
					</Text>
				</Stack>

				<form id="login_info" onSubmit={handleSubmit} style={{ width: '100%' }}>
					<FormControl isInvalid={!!errorMessages.email}>
						<Input type="email" placeholder="Email" value={email} onChange={handleEmailChange} />
						<FormErrorMessage>{errorMessages.email}</FormErrorMessage>
					</FormControl>

					<FormControl isInvalid={!!errorMessages.password} mt={4}>
						<InputGroup>
							<Input type={show ? 'text' : 'password'} placeholder="Password" value={password} onChange={handlePasswordChange} />
							<InputRightElement width='4.5rem'>
								<Button h='1.75rem' size='sm' onClick={handleClick}>
									{show ? 'Hide' : 'Show'}
								</Button>
							</InputRightElement>
						</InputGroup>
						<FormErrorMessage>{errorMessages.password}</FormErrorMessage>
					</FormControl>
				</form>
				<Stack direction="row" justifyContent="space-between" align="flex-start" width="full" mt={-6}>
					<Checkbox>
						Remember me
					</Checkbox>
					<Button variant="unstyled" size="sm" pb="5px">
						Forgot your password?
					</Button>
				</Stack>
				<Stack justify="flex-start" spacing="24px">
					<Button variant="confirm" type="submit" form="login_info">
						Log in
					</Button>
					<Center>
						<Text>
							Or continue with
						</Text>
					</Center>

					<Button variant="outline">
						Google
					</Button>
					<Button variant="outline">
						Apple
					</Button>
					<Button variant="outline">
						Phillips Hue
					</Button>
				</Stack>
			</Stack>
		</Stack>
	)
}

export default Login;