import { useNavigate, Navigate } from "react-router-dom";
import { Stack, Center, Image, Button, Text, Heading } from '@chakra-ui/react'
import { useAuth } from '../AuthContext';

import startIcon from '../assets/images/icons/start.png';

const Start = () => {
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();

    if (isLoggedIn === null) {
        return <p>Loading...</p>;
    }

    if (isLoggedIn)
    {
        return <Navigate to="/home" />;
    }

    return (
        <Stack
            direction="column"
            spacing="48px"
            align="center"
            pt={20}
            pl={4}
            pr={4}
        >
            <Center>
                <Image src={startIcon} alt="Desk lamp in a dark bedroom" />
            </Center>

            <Stack justify="flex-start" align="flex-start" spacing="16px">
                <Heading>
                    Welcome
                </Heading>
                <Text>
                    Welcome to PulsePower AI, where your home's energy and comfort pulse
                    with intelligent light and device management
                </Text>

            </Stack>
            <Center>
                <Button variant="confirm" onClick={() => navigate("/TOS")}>
                    Let’s start
                </Button>
            </Center>

        </Stack>
    );
}

export default Start;