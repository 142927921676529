import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [isAuthorized, setIsAuthorized] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            await checkStatus();
        };

        fetchData();
    }, []);

    const checkStatus = async () => {
        const loginResp = await fetch(process.env.REACT_APP_BACKEND_URL + '/auth/status', {
            method: 'GET',
            credentials: 'include'
        });
        if (loginResp.status == 200) {
            setIsLoggedIn(true);
        }
        else {
            setIsLoggedIn(false);
        }

        const authResp = await fetch(process.env.REACT_APP_BACKEND_URL + '/auth/hue_authorized', {
            method: 'GET',
            credentials: 'include'
        });
        if (authResp.status == 200) {
            setIsAuthorized(true);
        }
        else {
            setIsAuthorized(false);
        }
    }

    const login = async (email, password) => {
        try {
            const resp = await fetch(process.env.REACT_APP_BACKEND_URL + '/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "username": email,
                    "password": password
                }),
                credentials: 'include'
            });
            switch (resp.status) {
                case 400:
                    // username or password not sent
                    // TODO UI
                    setIsLoggedIn(false);
                    return false;
                case 401:
                    // invalid username or password
                    // TODO UI
                    setIsLoggedIn(false);
                    return false;
                case 200:
                    // successful authentication
                    setIsLoggedIn(true);
                    return true;
            }
        } catch (e) {
            console.log(e);
            setIsLoggedIn(false);
            return false;
        }
    };

    const logout = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/auth/logout', {
                method: 'POST',
                credentials: 'include', // Include cookies in the request
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                setIsLoggedIn(false);
                return true;
            } else {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('Logout failed:', error);
        }
        setIsLoggedIn(true);
        return false;
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, isAuthorized, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
