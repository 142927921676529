import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  IconButton,
  Text,
  VStack,
  HStack,
  Avatar,
  Switch,
  Spacer,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { FiHome, FiMessageCircle, FiGrid, FiSun, FiPower, FiPlus, FiMenu } from 'react-icons/fi';

const Spaces = () => {
  const [activeTab, setActiveTab] = useState('mySpaces');
  const [switches, setSwitches] = useState([true, false, false]);
  const [sliders, setSliders] = useState([50, 30, 70]);
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSwitchToggle = (index) => {
    const newSwitches = [...switches];
    newSwitches[index] = !newSwitches[index];
    setSwitches(newSwitches);
  };

  const handleSliderChange = (index, value) => {
    const newSliders = [...sliders];
    newSliders[index] = value;
    setSliders(newSliders);
  };

  return (
    <Box
      bgGradient="linear(to-b, blackAlpha.800, blackAlpha.600)"
      color="white"
      minH="100vh"
      display="flex"
      flexDirection="column"
      pb="70px" // Add padding at the bottom to account for the footer
    >
      <Container maxW="md" flex="1">
        {/* Header */}
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Heading size="md">My spaces</Heading>
          <IconButton icon={<FiMenu />} aria-label="Menu" variant="ghost" colorScheme="whiteAlpha" />
        </Flex>

        {/* My Spaces Section */}
        <Flex overflowX="auto" whiteSpace="nowrap" mb={4}>
          <Button h="100px" w="100px" bg="blue.500" color="white" mr={4} flexShrink={0}>
            <VStack>
              <Box bg="white" p={2} borderRadius="md">
                <FiHome size="24px" color="black" />
              </Box>
              <Text>Living room</Text>
            </VStack>
          </Button>
          <Button h="100px" w="100px" bg="gray.700" mr={4} flexShrink={0}>
            <VStack>
              <Box bg="white" p={2} borderRadius="md">
                <FiGrid size="24px" color="black" />
              </Box>
              <Text>Bathroom</Text>
            </VStack>
          </Button>
          <Button h="100px" w="100px" bg="gray.700" mr={4} flexShrink={0}>
            <VStack>
              <Box bg="white" p={2} borderRadius="md">
                <FiSun size="24px" color="black" />
              </Box>
              <Text>Kitchen</Text>
            </VStack>
          </Button>
          <Button h="100px" w="100px" bg="gray.700" flexShrink={0}>
            <VStack>
              <Box bg="white" p={2} borderRadius="md">
                <FiPlus size="24px" color="black" />
              </Box>
              <Text>New space</Text>
            </VStack>
          </Button>
        </Flex>

        {/* Devices Section */}
        <Flex justifyContent="space-between" alignItems="center" mb={2}>
          <Heading size="md">Devices</Heading>
          <Switch colorScheme="blue" size="lg" />
        </Flex>
        {switches.map((isOn, index) => (
          <Box bg="gray.700" p={4} borderRadius="md" mb={3} key={index}>
            <HStack justify="space-between">
              <VStack align="start">
                <Flex alignItems="center">
                  <Box bg={isOn ? "purple.500" : "gray.500"} p={2} borderRadius="md" mr={3}>
                    <FiPower size="24px" color="white" />
                  </Box>
                  <VStack align="start" spacing={0}>
                    <Text>Device ID</Text>
                    <Text fontWeight="bold">{index === 0 ? '123456' : '566798'}</Text>
                  </VStack>
                </Flex>
              </VStack>
              <Switch isChecked={isOn} onChange={() => handleSwitchToggle(index)} colorScheme="blue" size="lg" />
            </HStack>
            <Slider
              value={sliders[index]}
              onChange={(value) => handleSliderChange(index, value)}
              colorScheme="blue"
              mt={4}
            >
              <SliderTrack bg="gray.600">
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Box>
        ))}

        <Button bg="gray.700" w="full" h="50px" borderRadius="md" flexShrink={0}>
          <FiPlus size="24px" />
        </Button>
      </Container>

      {/* Footer */}
      <Flex
        position="fixed"
        bottom="0"
        left="0"
        w="full"
        justifyContent="space-around"
        bg="gray.800"
        p={2}
        borderTop="1px"
        borderColor="gray.600"
      >
        <IconButton
          icon={<FiHome />}
          aria-label="Home"
          variant={activeTab === 'home' ? 'solid' : 'ghost'}
          colorScheme="blue"
          onClick={() => {handleTabClick('home'); navigate("/home")}}
        />
        <IconButton
          icon={<FiMessageCircle />}
          aria-label="Tell AI"
          variant={activeTab === 'tellAI' ? 'solid' : 'ghost'}
          colorScheme="blue"
          onClick={() => handleTabClick('tellAI')}
        />
        <IconButton
          icon={<FiGrid />}
          aria-label="My spaces"
          variant={activeTab === 'mySpaces' ? 'solid' : 'ghost'}
          colorScheme="blue"
          onClick={() => handleTabClick('mySpaces')}
        />
        <IconButton
          icon={<FiSun />}
          aria-label="Daily glow"
          variant={activeTab === 'dailyGlow' ? 'solid' : 'ghost'}
          colorScheme="blue"
          onClick={() => handleTabClick('dailyGlow')}
        />
        <IconButton
          icon={<FiPower />}
          aria-label="Power"
          variant={activeTab === 'power' ? 'solid' : 'ghost'}
          colorScheme="blue"
          onClick={() => {handleTabClick('power'); navigate('/power')}}
        />
      </Flex>
    </Box>
  );
};

export default Spaces;
