import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  IconButton,
  Text,
  VStack,
  HStack,
  Spacer,
} from '@chakra-ui/react';
import { FiHome, FiMessageCircle, FiGrid, FiSun, FiPower } from 'react-icons/fi';

const Power = () => {
  const [activeTab, setActiveTab] = useState('power');
  const [selectedStat, setSelectedStat] = useState('energyUsed');
  const [selectedDay, setSelectedDay] = useState('W');
  const [dailyUsage, setDailyUsage] = useState([]);
  const [totalUsage, setTotalUsage] = useState(0);
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleStatClick = (stat) => {
    setSelectedStat(stat);
  };

  const handleBarClick = (day) => {
    setSelectedDay(day);
  };

  useEffect(() => {
    // Fetch the daily usage data from the backend
    fetch(process.env.REACT_APP_BACKEND_URL + '/api/usage')
      .then(response => response.json())
      .then(data => {
        // Extract last 7 days and total usage from response
        const { last_7_days, total_usage } = data;

        // Convert date strings to Date objects
        const parsedData = last_7_days.map(d => ({
          date: new Date(d.date),
          usage: d.usage,
        }));

        setDailyUsage(parsedData);
        setTotalUsage(total_usage);
      });
  }, []);

  return (
    <Box
      bgGradient="linear(to-b, blackAlpha.800, blackAlpha.600)"
      color="white"
      minH="100vh"
      display="flex"
      flexDirection="column"
      pb="70px" // Add padding at the bottom to account for the footer
    >
      <Container maxW="md" flex="1">
        {/* Header */}
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Heading size="md">Statistics</Heading>
        </Flex>

        {/* Statistics Section */}
        <Flex justifyContent="space-between" mb={4}>
          <Button
            h="80px"
            w="100px"
            bg={selectedStat === 'energyUsed' ? 'blue.500' : 'gray.700'}
            color={selectedStat === 'energyUsed' ? 'white' : 'gray.300'}
            onClick={() => handleStatClick('energyUsed')}
          >
            <VStack>
              <Box p={2}>
                <FiPower size="24px" color={selectedStat === 'energyUsed' ? 'white' : 'gray.300'} />
              </Box>
              <Text>Energy used</Text>
            </VStack>
          </Button>
          <Button
            h="80px"
            w="100px"
            bg={selectedStat === 'lightIntensity' ? 'blue.500' : 'gray.700'}
            color={selectedStat === 'lightIntensity' ? 'white' : 'gray.300'}
            onClick={() => handleStatClick('lightIntensity')}
          >
            <VStack>
              <Box p={2}>
                <FiSun size="24px" color={selectedStat === 'lightIntensity' ? 'white' : 'gray.300'} />
              </Box>
              <Text>Light intensity</Text>
            </VStack>
          </Button>
          <Button
            h="80px"
            w="100px"
            bg={selectedStat === 'trackedMovement' ? 'blue.500' : 'gray.700'}
            color={selectedStat === 'trackedMovement' ? 'white' : 'gray.300'}
            onClick={() => handleStatClick('trackedMovement')}
          >
            <VStack>
              <Box p={2}>
                <FiGrid size="24px" color={selectedStat === 'trackedMovement' ? 'white' : 'gray.300'} />
              </Box>
              <Text>Tracked movement</Text>
            </VStack>
          </Button>
        </Flex>

        {/* Divider */}
        <Box borderBottom="1px" borderColor="gray.600" my={4} />

        {/* Daily Usage Section */}
        <Heading size="md" mb={4}>
          Daily usage
        </Heading>
        <Box height="300px" mb={4}>
          <HStack justifyContent="space-between" alignItems="flex-start" height="100%">
            {dailyUsage.map((day, index) => (
              <VStack key={index} height="100%" justifyContent="end">
                <Box
                  height={`${(day.usage / Math.max(...dailyUsage.map(d => d.usage))) * 100}%`}
                  width="40px"
                  bg={selectedDay === day.date.toString() ? 'blue.500' : 'gray.600'}
                  borderRadius="md"
                  onClick={() => handleBarClick(day.date.toString())}
                  cursor="pointer"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-center"
                  position="relative"
                >
                  {selectedDay === day.date.toString() && (
                    <Text fontSize="xs" color="white" position="absolute" left="-6px" top="-20px" width="100px">
                      {day.usage} KWh
                    </Text>
                  )}
                </Box>
                <Text>{day.date.toDateString().slice(0, 3)}</Text>
              </VStack>
            ))}
          </HStack>
        </Box>

        <Box bg="gray.700" p={4} borderRadius="md" mb={3}>
          <HStack>
            <Box bg="blue.500" p={2} borderRadius="md">
              <FiPower size="24px" color="white" />
            </Box>
            <VStack align="start">
              <Text>Used</Text>
              <Text fontWeight="bold">Energy total</Text>
            </VStack>
            <Spacer />
            <Text>{totalUsage} KWh</Text>
          </HStack>
        </Box>
        <Box bg="gray.700" p={4} borderRadius="md" mb={3}>
          <HStack>
            <Box bg="green.500" p={2} borderRadius="md">
              <FiSun size="24px" color="white" />
            </Box>
            <VStack align="start">
              <Text>Saved</Text>
              <Text fontWeight="bold">Energy optimised</Text>
            </VStack>
            <Spacer />
            <Text>63 KWh</Text>
          </HStack>
        </Box>
        <Box bg="gray.700" p={4} borderRadius="md" mb={3}>
          <HStack>
            <Box bg="purple.500" p={2} borderRadius="md">
              <FiGrid size="24px" color="white" />
            </Box>
            <VStack align="start">
              <Text>Comfort</Text>
              <Text fontWeight="bold">Energy comfort</Text>
            </VStack>
            <Spacer />
            <Text>43 KWh</Text>
          </HStack>
        </Box>
      </Container>

      {/* Footer */}
      <Flex
        position="fixed"
        bottom="0"
        left="0"
        w="full"
        justifyContent="space-around"
        bg="gray.800"
        p={2}
        borderTop="1px"
        borderColor="gray.600"
      >
        <IconButton
          icon={<FiHome />}
          aria-label="Home"
          variant={activeTab === 'home' ? 'solid' : 'ghost'}
          colorScheme="blue"
          onClick={() => {handleTabClick('home'); navigate('/home')}}
        />
        <IconButton
          icon={<FiMessageCircle />}
          aria-label="Tell AI"
          variant={activeTab === 'tellAI' ? 'solid' : 'ghost'}
          colorScheme="blue"
          onClick={() => {handleTabClick('tellAI'); navigate('/chat')}}
        />
        <IconButton
          icon={<FiGrid />}
          aria-label="My spaces"
          variant={activeTab === 'mySpaces' ? 'solid' : 'ghost'}
          colorScheme="blue"
          onClick={() => {handleTabClick('mySpaces'); navigate('/spaces')}}
        />
        <IconButton
          icon={<FiSun />}
          aria-label="Daily glow"
          variant={activeTab === 'dailyGlow' ? 'solid' : 'ghost'}
          colorScheme="blue"
          onClick={() => {handleTabClick('dailyGlow'); navigate('/')}}
        />
        <IconButton
          icon={<FiPower />}
          aria-label="Power"
          variant={activeTab === 'power' ? 'solid' : 'ghost'}
          colorScheme="blue"
          onClick={() => {handleTabClick('power')}}
        />
      </Flex>
    </Box>
  );
};

export default Power;
