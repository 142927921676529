import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  VStack,
  HStack,
  Avatar,
  Switch,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Skeleton
} from '@chakra-ui/react';
import { FiHome, FiMessageCircle, FiGrid, FiSun, FiPower, FiPlus, FiChevronDown } from 'react-icons/fi';
import { useAuth } from "../AuthContext";

const Home = () => {
  const { logout } = useAuth();
  const [activeTab, setActiveTab] = useState('home');
  const [groups, setGroups] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getAllRooms = async () => {
      try {
        const resp = await fetch(process.env.REACT_APP_BACKEND_URL + '/hue/get_all_groups', {
          method: 'GET',
          credentials: 'include'
        });

        if (resp.ok) {
          const data = await resp.json();
          setGroups(data);
        }
      } catch (e) {
        console.log(e);
      }
    }

    getAllRooms();
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleLogout = async () => {
    const isLoggedOut = await logout();
    if (isLoggedOut) {
      navigate('/login');
    }
  };

  return (
    <Box
      bgGradient="linear(to-b, blackAlpha.800, blackAlpha.600)"
      color="white"
      minH="100vh"
      display="flex"
      flexDirection="column"
      pb="70px" // Add padding at the bottom to account for the footer
    >
      <Container maxW="md" flex="1">
        {/* Header */}
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Menu>
            <MenuButton as={Button} rightIcon={<FiChevronDown />}>
              <Avatar size="md" name="Firstname Lastname" />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </MenuList>
          </Menu>
          <Text>PulsePower</Text>
        </Flex>

        {/* Divider */}
        <Box borderBottom="1px" borderColor="gray.600" my={4} />

        {/* Main Content */}
        <VStack spacing={4} align="stretch">
          {/* AI Section */}
          <Box textAlign="center">
            <Text>Have a lighting scenario on your mind?</Text>
            <Button variant="confirm" size="lg" mt={2} onClick={() => navigate('/chat', { state: { selectedGroup } })}>
              Tell AI
            </Button>
          </Box>

          {/* My Spaces Section */}
          <Flex justifyContent="space-between" alignItems="center">
            <Heading size="md">My spaces</Heading>
            <Button variant="link" colorScheme="blue">
              View all
            </Button>
          </Flex>
          <Flex overflowX="auto" whiteSpace="nowrap" py={2}>
            {groups === null ? <Skeleton h="100px" w="100px" mr={4} flexShrink={0} /> : Object.keys(groups).map(groupID =>
              <Button key={groupID} h="100px" w="100px" bg="blue.500" color="white" mr={4} flexShrink={0} onClick={() => setSelectedGroup(groupID)}>
                <VStack>
                  <Text>{groups[groupID].name}</Text>
                  <Text>{groups[groupID].type}</Text>
                </VStack>
              </Button>
            )}
          </Flex>
          {/* Power Usage Section */}
          <Flex justifyContent="space-between" alignItems="center">
            <Heading size="md">Power usage</Heading>
            <Button variant="link" colorScheme="blue">
              View all
            </Button>
          </Flex>
          <Box bg="gray.700" p={4} borderRadius="md">
            <HStack>
              <Box bg="blue.500" p={2} borderRadius="md">
                <FiPower size="24px" color="white" />
              </Box>
              <VStack align="start">
                <Text>Used</Text>
                <Text fontWeight="bold">Energy total</Text>
              </VStack>
              <Spacer />
              <Text>123 KWh</Text>
            </HStack>
          </Box>

          {/* My Scenarios Section */}
          <Flex justifyContent="space-between" alignItems="center">
            <Heading size="md">My scenarios</Heading>
            <Button variant="link" colorScheme="blue">
              View all
            </Button>
          </Flex>
          <Box bg="gray.700" p={4} borderRadius="md" mb={3}>
            <HStack justify="space-between">
              <VStack align="start">
                <Text>Current</Text>
                <HStack>
                  <Text>7:00 - 12:30</Text>
                  <Text>Mon-Fri</Text>
                  <Text>2 spaces</Text>
                </HStack>
              </VStack>
              <Switch colorScheme="blue" size="lg" />
            </HStack>
          </Box>
        </VStack>
      </Container>

      {/* Footer */}
      <Flex
        position="fixed"
        bottom="0"
        left="0"
        w="full"
        justifyContent="space-around"
        bg="gray.800"
        p={2}
        borderTop="1px"
        borderColor="gray.600"
      >
        <IconButton
          icon={<FiHome />}
          aria-label="Home"
          variant={activeTab === 'home' ? 'solid' : 'ghost'}
          colorScheme="blue"
          onClick={() => handleTabClick('home')}
        />
        <IconButton
          icon={<FiMessageCircle />}
          aria-label="Tell AI"
          variant={activeTab === 'tellAI' ? 'solid' : 'ghost'}
          colorScheme="blue"
          onClick={() => { handleTabClick('tellAI'); navigate('/chat') }}
        />
        <IconButton
          icon={<FiGrid />}
          aria-label="My spaces"
          variant={activeTab === 'mySpaces' ? 'solid' : 'ghost'}
          colorScheme="blue"
          onClick={() => { handleTabClick('mySpaces'); navigate("/spaces") }}
        />
        <IconButton
          icon={<FiSun />}
          aria-label="Daily glow"
          variant={activeTab === 'dailyGlow' ? 'solid' : 'ghost'}
          colorScheme="blue"
          onClick={() => handleTabClick('dailyGlow')}
        />
        <IconButton
          icon={<FiPower />}
          aria-label="Power"
          variant={activeTab === 'power' ? 'solid' : 'ghost'}
          colorScheme="blue"
          onClick={() => {
            handleTabClick('power');
            navigate('/power');
          }}
        />
      </Flex>
    </Box>
  );
};

export default Home;
