import { useState, useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Alert, Box, Input, Center, CircularProgress, CircularProgressLabel, Icon, Text, Stack, Button, Heading, AlertIcon } from "@chakra-ui/react";
import { FiMic, FiMicOff, FiCamera } from 'react-icons/fi';
import { AutoResizeTextarea } from '../Components/AutoResizeTextarea';
import { useLocation } from 'react-router-dom';

const Chat = () => {
    const location = useLocation();
    const { selectedGroup } = location.state || {};

    const [instructions, setInstructions] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable
    } = useSpeechRecognition();

    useEffect(() => {
        setInstructions(transcript);
    }, [transcript]);

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    if (!isMicrophoneAvailable) {
        return <span>Microphone permission has been denied.</span>;
    }

    const handlePress = async () => {
        if (listening) {
            resetTranscript();
            await SpeechRecognition.stopListening();
        }
        else {
            await SpeechRecognition.startListening();
        }
    }

    const handleChange = (e) => {
        setInstructions(e.target.value);
    }

    const handleFileSelect = (event) => {
        if (!selectedFile) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleSubmit = async () => {
        // send photo if uploaded and transcript to AI backend
        const formData = new FormData();
        if (selectedFile) {
            formData.append('photo', selectedFile);
        }
        // need to add both the instructions from the text box and the voice input if it is avaliable
        if (transcript) {
            formData.append('instructions', instructions);
        }

        if (instructions) {
            formData.append('instructions', instructions);
        }

        formData.append("group_id", selectedGroup);

        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/change_light_configuration', {
                method: 'POST',
                body: formData,
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error during fetch operation:', error);
        }
    }

    return (
        <Box mt="40%">
            <Center>
                <Stack align="center">
                    <Heading>Press to talk to AI</Heading>
                    <Box onClick={handlePress} cursor='pointer'>
                        <CircularProgress trackColor="#16161F" color="#501587" size='150px' thickness='4px' capIsRound isIndeterminate={listening}>
                            <CircularProgressLabel>
                                <Icon as={listening ? FiMicOff : FiMic} />
                            </CircularProgressLabel>
                        </CircularProgress>
                    </Box>
                    <AutoResizeTextarea value={instructions}
                        onChange={handleChange}
                        mb="24px"
                    />
                    <Input
                        type="file"
                        accept="image/*"
                        capture="camera"
                        onChange={handleFileSelect}
                        style={{ display: 'none' }}
                        id="upload-photo-input"
                    />
                    <label htmlFor="upload-photo-input">
                        <Stack align="center">
                            <Stack direction="row" align="center">
                                <Text mr="6px">Take photo</Text>
                                <Icon as={FiCamera} boxSize={8} />
                            </Stack>
                            {selectedFile && <Alert status='success'><AlertIcon />Your photo was uploaded!</Alert>}
                        </Stack>
                    </label>
                    <Button variant="confirm" mt='48px' onClick={handleSubmit} isDisabled={!selectedFile && instructions === ''}>
                        Update lights
                    </Button>
                </Stack>
            </Center>
        </Box>
    );
}

export default Chat;