import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    styles: {
        global: {
            body: {
                backgroundColor: "#05050E",
                backgroundImage: `
          radial-gradient(circle at 45deg, rgba(8, 196, 208, 0.3), rgba(34, 35, 72, 0)),
          radial-gradient(circle at 45deg, rgba(141, 37, 237, 0.3), rgba(34, 35, 72, 0))
        `,
                backgroundSize: "cover",
                backgroundBlendMode: "overlay",
                color: "white",
            },
        },
    },
    fonts: {
        body: "Kanit, sans-serif",
        heading: "Kanit, sans-serif",
        mono: "Menlo, monospace",
    },
    fontWeights: {
        thin: 100,
        extralight: 200,
        light: 300,
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        extrabold: 800,
        black: 900,
    },
    fontStyles: {
        normal: "normal",
        italic: "italic",
    },
    components: {
        Button: {
            sizes: {
                xl: {
                    h: "56px",
                    fontSize: "lg",
                },
            },
            variants: {
                solid: {
                    bg: "teal.500",
                    color: "white",
                    _hover: {
                        bg: "teal.600",
                    },
                },
                outline: {
                    border: "1px solid",
                    borderColor: "#FFFFFF",
                    color: "#FFFFFF",
                    _hover: {
                        bg: "teal.100",
                    },
                    fontWeight: "bold",
                    borderRadius: "90px",
                    paddingX: "24px",
                    paddingY: "12px",
                    direction: "row",
                    justify: "center",
                    align: "center",
                    spacing: "10px",
                    width: "330px",
                    maxWidth: "100%",
                    boxShadow: "0px 18px 34.4px 0px rgba(21, 115, 254, 0.25)",
                    _focus: {
                        boxShadow: "0 0 0 3px rgba(21, 115, 254, 0.6)",
                    },
                },
                confirm: {
                    bg: "#1573FE",
                    color: "white",
                    _hover: {
                        bg: "#125FCF",
                    },
                    fontWeight: "bold",
                    borderRadius: "90px",
                    paddingX: "24px",
                    paddingY: "12px",
                    direction: "row",
                    justify: "center",
                    align: "center",
                    spacing: "10px",
                    width: "330px",
                    maxWidth: "100%",
                    boxShadow: "0px 18px 34.4px 0px rgba(21, 115, 254, 0.25)",
                    _focus: {
                        boxShadow: "0 0 0 3px rgba(21, 115, 254, 0.6)",
                    },
                },
            },
            defaultProps: {
                size: "md",
                variant: "solid",
            },
        },
    },
});

export default theme;
