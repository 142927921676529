import { useNavigate } from "react-router-dom";
import { Stack, Center, Image, Button, Text, Heading } from '@chakra-ui/react'

import authIcon from '../assets/images/icons/auth.png';

const Auth = () => {
    const navigate = useNavigate();

    return (
        <Stack
            direction="column"
            spacing="48px"
            pt={20}
            pl={4}
            pr={4}
        >
            <Center>
                <Image src={authIcon} alt="Laptop with log in screen" />
            </Center>

            <Center>
                <Stack spacing="24px">
                    <Heading>
                        Sign in or register
                    </Heading>
                    <Button variant="confirm" onClick={() => navigate("/login")}>
                        Sign In
                    </Button>
                    <Button variant="outline" onClick={() => navigate("/register")}>
                        Register
                    </Button>
                </Stack>
            </Center>
        </Stack>
    );
}

export default Auth;